import React from "react";
import { Image } from "react-bootstrap";

const Productscard = (props) => {
  const { image } = props;

  return (
    <div className="products-card">
      <Image src={image} fluid={true} className="products-card__image" />
    </div>
  );
};

export default Productscard;
