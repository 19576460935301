import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import aboutImage from "../images/about-image.svg";
import Title from "../components/Title";
import Text from "../components/Text";

const AboutUs = () => {
  return (
    <div id="about" className="about">
      <Container>
        <Row>
          <Col xl={6} className="about__col-left">
            <Image fluid={true} src={aboutImage} className="about__image" />
          </Col>
          <Col xl={6} className="about__col-right">
            <Title title="About US" />
            <Text content="Nutra Prime is the flagship brand of The Vitacare Pharma, a family of wellness brands committed to provide people with high quality products to complement their lifestyles and physical health." />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
