import React from "react";
import { Image } from "react-bootstrap";
import { WhatsApp } from "../CompanyDetails";
import whatsappIcon from "../images/whatsapp-icon.svg";

const WhatsappChat = () => {
  return (
    <a
      href={`https://wa.me/${WhatsApp}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image src={whatsappIcon} fluid={true} className="whatsapp-chat" />
    </a>
  );
};

export default WhatsappChat;
