import * as React from "react";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Products from "../components/Products";
import WhatsappChat from "../components/WhatsappChat";
import Wrapper from "../components/Wrapper";

const IndexPage = () => {
  return (
    <Wrapper>
      <Hero />
      <AboutUs />
      <Products />
      <Footer />
      <WhatsappChat />
      {/* <main className="wrapper">
        <Image src={logo} fluid={true} className="logo" />
        <h1 className="heading">WEBSITE UNDER MAINTENANCE...</h1>
        <h1 className="sub-heading">We will be back soon !</h1>
        <div className="text-wrapper">
          <h3>Marketed by Nutra Prime</h3>
          <div className="text-sub-wrapper">
            <h3>Manufactured by Vita Care Pharma (New Jersey, USA)</h3>
            <h4>
              <a href="http://www.vitacarepharma.com/">
                www.vitacarepharma.com
              </a>
            </h4>
          </div>
          <div className="flag-wrapper">
            <Image src={flag} fluid={true} className="flag" />
            <h6>Made in USA</h6>
          </div>
        </div>
      </main> */}
    </Wrapper>
  );
};

export default IndexPage;
