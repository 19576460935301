import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import heroImage from "../images/hero-image.svg";

const Hero = () => {
  return (
    <div id="home" className="hero">
      <Container>
        <Row>
          <Col xl={6} className="hero__col-left">
            <h1 className="hero__title">NUTRA PRIME</h1>
            <h2 className="hero__sub-title">Live Healthy</h2>
            <h3 className="hero__sub-title">Stay Strong</h3>
          </Col>
          <Col xl={6} className="hero__col-right">
            <Image fluid={true} src={heroImage} className="hero__image" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
