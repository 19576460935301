import React from "react";
import { Link } from "react-scroll";
import { Container, Row, Col, Image } from "react-bootstrap";
import fb from "../images/facebook.svg";
import addressIcon from "../images/pin.svg";
import phoneIcon from "../images/phone-call.svg";
import { Address, FacebookAccountURL, PhoneNo } from "../CompanyDetails";

const Footer = () => {
  return (
    <footer id="contact-us" className="footer">
      <Container>
        <Row>
          <Col md={4} lg={4} xl={4} className="footer__col">
            <div className="footer__title">
              Nutra<span className="footer__span">Prime</span>
            </div>

            <div className="footer__icon-wrapper">
              <Image
                fluid={true}
                className="footer__new-icon"
                src={phoneIcon}
              />
              <a href={`tel:${PhoneNo}`} className="footer__phone">
                {PhoneNo}
              </a>
            </div>
            <div className="footer__icon-wrapper">
              <Image
                fluid={true}
                className="footer__new-icon"
                src={addressIcon}
              />
              <div className="footer__address">{Address}</div>
            </div>
          </Col>
          <Col md={2} lg={2} xl={2} className="footer__col">
            <div className="footer__heading">Company</div>

            <Link className="footer__text" to="about" spy={true} offset={-100}>
              About Us
            </Link>

            <Link
              className="footer__text"
              to="products"
              spy={true}
              offset={-100}
            >
              Products
            </Link>
          </Col>
          <Col md={2} lg={2} xl={2} className="footer__col">
            <div className="footer__heading">Follow Us</div>
            <div className="footer__icons-wrapper">
              <a href={FacebookAccountURL} className="footer__link">
                <Image src={fb} className="footer__icon" />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="footer__copyright-wrapper">
              <p className="footer__copyright-text">
                Copyrights © 2023 | Nutra Prime
              </p>
              <p className="footer__copyright-small-text">
                Marketed by Nutra Prime, Manufactured by Vita Care Pharma (New
                Jersey, USA)
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
