import React from "react";
import Navigation from "./NavBar";

const Wrapper = (props) => {
  return (
    <>
      <Navigation />
      <div>{props.children}</div>;
    </>
  );
};

export default Wrapper;
