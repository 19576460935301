import React from "react";
import Logo from "../images/logo.png";
import { Image } from "react-bootstrap";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "react-scroll";
import phoneIcon from "../images/phone-call.svg";
import { PhoneNo } from "../CompanyDetails";

import { CloseCircleOutlined, MenuOutlined } from "@ant-design/icons";

// import { NavGatsbyLink } from "react-router-dom";

const Navigation = () => {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container">
          <GatsbyLink exact to="/" className="nav-logo-wrapper">
            <Image className="nav-logo" src={Logo} fluid={true} />
          </GatsbyLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <div className="nav-item-wrapper">
              <li>
                <GatsbyLink
                  to="/"
                  activeClassName="active"
                  className="nav-GatsbyLinks"
                  onClick={click ? handleClick : null}
                >
                  <Link to="home" spy={true}>
                    <div className="nav-btn">Home</div>
                  </Link>
                </GatsbyLink>
              </li>
              <li>
                <GatsbyLink
                  // to="/"
                  activeClassName="active"
                  className="nav-GatsbyLinks"
                  onClick={click ? handleClick : null}
                >
                  <Link to="about" spy={true} offset={-100}>
                    <div className="nav-btn">About</div>
                  </Link>
                </GatsbyLink>
              </li>
              <li>
                <GatsbyLink
                  // to="/"
                  activeClassName="active"
                  className="nav-GatsbyLinks"
                  onClick={click ? handleClick : null}
                >
                  <Link to="products" spy={true}>
                    <div className="nav-btn">Products</div>
                  </Link>
                </GatsbyLink>
              </li>
              <li>
                <GatsbyLink
                  // to="/"
                  activeClassName="active"
                  className="nav-GatsbyLinks"
                  onClick={click ? handleClick : null}
                >
                  <Link to="contact-us" spy={true}>
                    <div className="nav-btn">Contact Us</div>
                  </Link>
                </GatsbyLink>
              </li>
            </div>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {click ? <CloseCircleOutlined /> : <MenuOutlined />}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
