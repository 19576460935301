import React from "react";
import Productscard from "./ProductsCard";
import folicAcid from "../images/products/folic-acid.jpg";
import b12 from "../images/products/b-12.jpg";
import mensHealth from "../images/products/mens-health.jpg";
import womensHealth from "../images/products/womens-health.jpg";
import vitaminE from "../images/products/vitamin-e.jpg";
import glutathione from "../images/products/glutathione.jpg";
import femicos from "../images/products/femicos.jpg";
import biotin from "../images/products/biotin.jpg";
import c500 from "../images/products/c-500.jpg";
import c1000 from "../images/products/c-1000.jpg";
import calcium from "../images/products/calcium.jpg";
import magnesium from "../images/products/magnesium.jpg";
import omega from "../images/products/omega.jpg";
import tribugen from "../images/products/tribugen.jpg";
import salmon from "../images/products/salmon.jpeg";
import { Container } from "react-bootstrap";
import Title from "../components/Title";

const Products = () => {
  return (
    <Container id="products">
      <Title title="Our Products" />
      <div className="products">
        <Productscard image={folicAcid} />
        <Productscard image={b12} />
        <Productscard image={mensHealth} />
        <Productscard image={womensHealth} />
        <Productscard image={vitaminE} />
        <Productscard image={glutathione} />
        <Productscard image={femicos} />
        <Productscard image={biotin} />
        <Productscard image={c500} />
        <Productscard image={c1000} />
        <Productscard image={calcium} />
        <Productscard image={magnesium} />
        <Productscard image={omega} />
        <Productscard image={tribugen} />
        <Productscard image={salmon} />
      </div>
    </Container>
  );
};

export default Products;
